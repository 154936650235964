<script setup lang="ts">
import { v4 as uuid } from 'uuid';
import { Chart, registerables } from 'chart.js';
import type { ChartData, ChartTypeRegistry } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import type { ChartJSWrapperPlugins } from '~/types/chartjs';

import 'chartjs-adapter-date-fns'; // For time cartesian axis

/**
 * @type : String
 * (The type of the chart : https://www.chartjs.org/docs/latest/ => Chart Types)
 *
 * @data : ChartData
 * (The data of the chart, be carefull of your datasets format matching the type of the chart)
 *
 * @options : Object
 * (https://www.chartjs.org/docs/latest/general/options.html)
 *
 * @plugins : Object
 * (https://www.chartjs.org/docs/latest/developers/plugins.html)
 */
const props = defineProps({
  disabledButton: {
    type: Boolean,
    required: false,
    default: false,
  },
  type: {
    type: String as () => keyof ChartTypeRegistry,
    required: true,
  },
  data: {
    type: Object as PropType<ChartData>,
    required: true,
  },
  options: {
    type: Object as PropType<any>,
    required: false,
    default: () => ({}),
  },
  plugins: {
    type: Object as PropType<ChartJSWrapperPlugins>,
    required: false,
    default: () => ({
      'chartjs-plugin-zoom': false,
      'chartjs-plugin-annotation': false,
      legend: false,
    }),
  },
  resetZoom: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const canvas = ref<HTMLCanvasElement | null>(null);
const isZoomed = ref(false);
const emit = defineEmits<{
  (e: 'label-click', value: [data: any, label: string]): void;
  (e: 'bar-click', value: number): void;
  (e: 'zoom', startLabelIndex: number, endLabelIndex: number, isUnzoom?: boolean): void;
}>();

let chart: Chart;

const state = reactive<{
  id: string;
  defaultData: ChartData;
  defaultOptions: any;
}>({
  id: uuid(),
  defaultData: {
    labels: ['default label 1', 'default label 2', 'default label 3'],
    datasets: [
      {
        label: 'dataset default',
        data: [1, 2, 3],
      },
    ],
  },
  defaultOptions: {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          mode: 'x',
          onZoom: (data: any) => {
            emit('zoom', data.chart.scales.x.min, data.chart.scales.x.max);
          },
          onZoomComplete: () => {
            isZoomed.value = true;
          },
        },
      },
      legend: {
        display: props.plugins.legend,
        position: props.plugins['chartjs-plugin-zoom'] ? 'bottom' : 'top',
      },
    },

    onHover: function (event: any, chartElement: any) {
      if (chartElement.length === 1) {
        event.native.target.style.cursor = 'pointer';
      } else {
        event.native.target.style.cursor = 'default';
      }
    },

    onClick: (evt) => {
      const activePoints = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

      if (activePoints.length > 0) {
        emit('bar-click', activePoints[0].index);
      }
      if (activePoints.length) {
        const firstPoint = activePoints[0];
        const label = chart.data.labels[firstPoint.index];
        emit('label-click', [firstPoint, label as string]);
      }
    },
  },
});

function resetZoomChart() {
  chart.resetZoom();
  isZoomed.value = false;
  emit('zoom', 0, props.data.labels?.length - 1 || 0, true);
}

function generateData() {
  return props.data ? props.data : state.defaultData;
}

function generateOptions() {
  return {
    ...state.defaultOptions,
    ...props.options,
    plugins: {
      ...state.defaultOptions.plugins,
      ...props.options?.plugins,
    },
  };
}

function generatePlugins() {
  const plugins = [];

  if (props.plugins['chartjs-plugin-zoom']) plugins.push(zoomPlugin);

  return plugins;
}

function loadChart() {
  if (!canvas.value) return;
  const ctx = canvas.value.getContext('2d');

  Chart.register(...registerables);

  // Annotations must be imported via registerables
  if (props.plugins['chartjs-plugin-annotation']) Chart.register(annotationPlugin);

  chart = new Chart(ctx, {
    type: props.type,
    data: generateData(),
    options: generateOptions(),
    plugins: generatePlugins(),
  });
}

onMounted(() => {
  loadChart();
});

watch(
  () => props.data,
  () => {
    chart.data = generateData();
    chart.update();
  },
);

watch(
  () => props.options,
  () => {
    chart.options = generateOptions();
    chart.update();
  },
);

watch(
  () => props.type,
  () => {
    chart.destroy();
    loadChart();
  },
);

watch(
  () => props.resetZoom,
  () => {
    if (props.resetZoom) {
      resetZoomChart();
    }
  },
);
</script>

<template>
  <div class="relative flex h-[50vh] flex-col justify-end w-full">
    <slot v-if="!disabledButton" name="button">
      <div class="flex items-center justify-end absolute top-2 right-0">
        <ui-button color="secondary" size="sm" :disabled="!isZoomed" @click="resetZoomChart()">
          {{ $t('global.unzoom') }}
        </ui-button>
      </div>
    </slot>

    <canvas ref="canvas" class="w-[100%] h-[100%]" />
  </div>
</template>
